import {createStore} from "../_snowpack/pkg/svelte-restate.js";
const initState = {
  isAdmin: false,
  screen: "artwork",
  artworkUids: [],
  currentArtworkId: null,
  entities: {
    artworks: {}
  },
  meta: {
    artworks: {
      orderByParams: ["createdAt", "desc"],
      isLoading: true,
      isAllLoaded: false,
      totalCount: 0
    }
  }
};
const appDB = createStore(initState, {propagateUndefined: false});
export default appDB;
