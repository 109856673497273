/* src/components/comments.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../../_snowpack/pkg/svelte.js';

function create_fragment(ctx) {
	let script;
	let t1;
	let div;

	return {
		c() {
			script = element("script");
			script.textContent = "var remark_config = {\n      host: \"https://comments.stephanart.work\",\n      site_id: \"gallery\",\n      components: [\"embed\"],\n      theme: \"dark\",\n      simple_view: true,\n      no_footer: true\n    };\n    !(function (e, n) {\n      for (var o = 0; o < e.length; o++) {\n        var r = n.createElement(\"script\"),\n          c = \".js\",\n          d = n.head || n.body;\n        \"noModule\" in r ? ((r.type = \"module\"), (c = \".mjs\")) : (r.async = !0),\n          (r.defer = !0),\n          (r.src = remark_config.host + \"/web/\" + e[o] + c),\n          d.appendChild(r);\n      }\n    })(remark_config.components || [\"embed\"], document);";
			t1 = space();
			div = element("div");
			script.async = true;
			attr(script, "lang", "javascript");
			attr(div, "id", "remark42");
		},
		m(target, anchor) {
			append(document.head, script);
			insert(target, t1, anchor);
			insert(target, div, anchor);
			/*div_binding*/ ctx[1](div);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			detach(script);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			/*div_binding*/ ctx[1](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const remarkConfig = {
		host: 'https://comments.stephanart.work',
		site_id: 'gallery',
		components: ['embed'],
		theme: 'dark',
		simple_view: true,
		no_footer: true
	};

	let remarkInstance;
	let remarkEl;

	function initRemark() {
		if (window.REMARK42) {
			if (remarkInstance) {
				remarkInstance.destroy();
			}

			remarkInstance = window.REMARK42.createInstance(Object.assign({ node: remarkEl }, remarkConfig));
		}
	}

	onMount(() => {
		if (window.REMARK42) {
			initRemark();
		} else {
			window.addEventListener('REMARK42::ready', () => {
				initRemark();
			});
		}
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			remarkEl = $$value;
			$$invalidate(0, remarkEl);
		});
	}

	return [remarkEl, div_binding];
}

class Comments extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Comments;