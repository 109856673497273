import dayjs from "../_snowpack/pkg/dayjs.js";
import duration from "../_snowpack/pkg/dayjs/plugin/duration.js";
import relativeTime from "../_snowpack/pkg/dayjs/plugin/relativeTime.js";
dayjs.extend(duration);
dayjs.extend(relativeTime);
const DEFAULT_NAME = "Untitled Artwork";
export function displayName(artwork) {
  return artwork.name || DEFAULT_NAME;
}
export function displayTrackedTime(artwork) {
  return dayjs.duration(artwork.trackedTime, "seconds").humanize();
}
