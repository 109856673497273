import './artworks_screen.svelte.css';
/* src/components/artworks_screen.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import ArtworkScreen from './artwork_screen.svelte.js';
import Artworks from './artworks.svelte.js';
import Navbar from './navbar.svelte.js';
import subs from '../subs.js';

function create_if_block(ctx) {
	let artworkscreen;
	let current;
	artworkscreen = new ArtworkScreen({});

	return {
		c() {
			create_component(artworkscreen.$$.fragment);
		},
		m(target, anchor) {
			mount_component(artworkscreen, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(artworkscreen.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(artworkscreen.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(artworkscreen, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let navbar;
	let t0;
	let artworks;
	let t1;
	let if_block_anchor;
	let current;
	navbar = new Navbar({});
	artworks = new Artworks({});
	let if_block = /*$currentArtworkId*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			create_component(navbar.$$.fragment);
			t0 = space();
			create_component(artworks.$$.fragment);
			t1 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(div, "class", "wrapper svelte-1fl7sh6");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(navbar, div, null);
			append(div, t0);
			mount_component(artworks, div, null);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$currentArtworkId*/ ctx[0]) {
				if (if_block) {
					if (dirty & /*$currentArtworkId*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(artworks.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(artworks.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(navbar);
			destroy_component(artworks);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $currentArtworkId;
	let currentArtworkId = subs.currentArtworkId();
	component_subscribe($$self, currentArtworkId, value => $$invalidate(0, $currentArtworkId = value));
	return [$currentArtworkId, currentArtworkId];
}

class Artworks_screen extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Artworks_screen;