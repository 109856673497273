import { c as createCommonjsModule } from '../common/_commonjsHelpers-eb5a497e.js';

var devtools = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', { value: true });

function connectToDevTools(store, muts) {
    if (muts === void 0) { muts = {}; }
    var windowObj = window;
    var devTools = windowObj.__REDUX_DEVTOOLS_EXTENSION__ && windowObj.__REDUX_DEVTOOLS_EXTENSION__.connect({
        name: document.title,
        actionCreators: muts
    });
    if (!devTools) {
        console.info("Could not connect to redux devtools");
        return;
    }
    devTools.init(store.root.getState());
    devTools.subscribe(function (message) {
        if (message.type === "DISPATCH" && (message.payload.type === "JUMP_TO_ACTION" || message.payload.type === "JUMP_TO_STATE") && message.state) {
            var state = JSON.parse(message.state);
            store.set(state);
        }
        if (message.type === "ACTION" && message.payload.name) {
            if (muts.hasOwnProperty(message.payload.name)) {
                var handler = muts[message.payload.name];
                handler.apply(null, message.payload.args);
            }
        }
    });
    // setup subscription store
    var devToolsSubs = windowObj.__REDUX_DEVTOOLS_EXTENSION__ && windowObj.__REDUX_DEVTOOLS_EXTENSION__.connect({
        name: document.title + " | Subscriptions"
    });
    if (!devToolsSubs) {
        console.info("Could not connect to redux devtools");
        return;
    }
    devToolsSubs.init(store.getRunningSubsState());
    store.listenSubs(function (action, name) {
        devToolsSubs.send({ type: action, payload: { name: name } }, store.getRunningSubsState());
    });
    store.listenMuts(function (name, params) {
        devTools.send({ type: name, payload: params }, store.root.getState());
        devToolsSubs.send({ type: name, payload: params }, store.getRunningSubsState());
    });
}

exports.connectToDevTools = connectToDevTools;

});

var connectToDevTools = devtools.connectToDevTools;
export { connectToDevTools };
