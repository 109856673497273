import './artworks.svelte.css';
/* src/components/artworks.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	outro_and_destroy_block,
	safe_not_equal,
	space,
	transition_in,
	transition_out,
	update_keyed_each
} from "../../_snowpack/pkg/svelte/internal.js";

import { loadArtworks } from '../interactors/artwork.js';
import subs from '../subs.js';
import ArtworkCard from './artwork_card.svelte.js';
import { infiniteScroll } from '../utils.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (40:2) {#if $artworkUids.length}
function create_if_block_1(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value = /*$artworkUids*/ ctx[1];
	const get_key = ctx => /*id*/ ctx[8];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$artworkUids*/ 2) {
				each_value = /*$artworkUids*/ ctx[1];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block, each_1_anchor, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
		}
	};
}

// (41:4) {#each $artworkUids as id (id)}
function create_each_block(key_1, ctx) {
	let first;
	let artworkcard;
	let current;
	artworkcard = new ArtworkCard({ props: { id: /*id*/ ctx[8] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(artworkcard.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(artworkcard, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const artworkcard_changes = {};
			if (dirty & /*$artworkUids*/ 2) artworkcard_changes.id = /*id*/ ctx[8];
			artworkcard.$set(artworkcard_changes);
		},
		i(local) {
			if (current) return;
			transition_in(artworkcard.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(artworkcard.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(artworkcard, detaching);
		}
	};
}

// (45:2) {#if canLoad}
function create_if_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			/*div_binding*/ ctx[6](div);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			/*div_binding*/ ctx[6](null);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let t;
	let current;
	let if_block0 = /*$artworkUids*/ ctx[1].length && create_if_block_1(ctx);
	let if_block1 = /*canLoad*/ ctx[2] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			attr(div, "class", "gallery svelte-apkend");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append(div, t);
			if (if_block1) if_block1.m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$artworkUids*/ ctx[1].length) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*$artworkUids*/ 2) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div, t);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*canLoad*/ ctx[2]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let canLoad;
	let $artworksMeta;
	let $artworkUids;
	let artworkUids = subs.artworkUids();
	component_subscribe($$self, artworkUids, value => $$invalidate(1, $artworkUids = value));
	let artworksMeta = subs.artworksMeta();
	component_subscribe($$self, artworksMeta, value => $$invalidate(5, $artworksMeta = value));
	let infiniteScrollRef = null;

	function loadMore() {
		if (canLoad) loadArtworks();
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			infiniteScrollRef = $$value;
			$$invalidate(0, infiniteScrollRef);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$artworkUids, $artworksMeta*/ 34) {
			$: $$invalidate(2, canLoad = $artworkUids.length && !$artworksMeta.isLoading && !$artworksMeta.isAllLoaded);
		}

		if ($$self.$$.dirty & /*infiniteScrollRef*/ 1) {
			$: {
				if (infiniteScrollRef) infiniteScroll({
					fetch: loadMore,
					element: infiniteScrollRef
				});
			}
		}
	};

	return [
		infiniteScrollRef,
		$artworkUids,
		canLoad,
		artworkUids,
		artworksMeta,
		$artworksMeta,
		div_binding
	];
}

class Artworks extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Artworks;