import {normalize, schema} from "../../_snowpack/pkg/normalizr.js";
import * as api from "../api.js";
import muts from "../muts.js";
import subs from "../subs.js";
import appDB from "../db.js";
import {goToArtworks} from "../routes.js";
const artworkSchema = new schema.Entity("artworks", {}, {idAttribute: "uid"});
export async function loadArtworks() {
  muts.setArtworksLoading(true);
  const artworksMeta = subs.artworksMeta().getState();
  const artworkUids = subs.artworkUids().getState();
  const loadedArtworksCount = artworkUids.length;
  const resp = await api.getArtworks({
    offset: loadedArtworksCount,
    orderByParams: artworksMeta.orderByParams,
    withCount: !loadedArtworksCount
  });
  if (resp.totalCount) {
    muts.setArtworksMeta({totalCount: resp.totalCount});
  }
  if (resp.artworks.length) {
    const normalized = normalize(resp.artworks, [artworkSchema]);
    const artworkEntities = normalized.entities.artworks;
    appDB.transaction((tx) => [
      muts.setArtworksLoading(false, tx),
      muts.appendArtworks([normalized.result, artworkEntities], tx)
    ]);
    return;
  }
  muts.setArtworksMeta({isLoading: false, isAllLoaded: true});
}
export async function loadArtwork(id) {
  muts.setArtworksLoading(true);
  const artwork = await api.getArtwork(id);
  if (artwork) {
    appDB.transaction((tx) => [
      muts.setArtworksLoading(false, tx),
      muts.insertArtwork(artwork, tx)
    ]);
  }
}
export async function toggleArtworkVisability(uid, isPublic) {
  muts.updateArtwork([uid, {isPublic}]);
  await api.toggleArtworkVisability(uid, isPublic);
}
export async function deleteArtwork(id) {
  appDB.transaction((tx) => [
    muts.setCurrentArtworkId(null, tx),
    muts.setCurrentScreen("artworks", tx),
    muts.deleteArtwork(id, tx)
  ]);
  goToArtworks();
  await api.deleteArtwork(id);
}
export async function changeArtworksOrder(order) {
  appDB.transaction((tx) => [
    muts.setArtworksMeta({orderByParams: ["createdAt", order]}, tx),
    muts.setArtworks([[], {}], tx)
  ]);
  loadArtworks();
}
