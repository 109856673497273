import './navbar.svelte.css';
/* src/components/navbar.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	toggle_class
} from "../../_snowpack/pkg/svelte/internal.js";

import subs from '../subs.js';
import { goToArtworks } from '../routes.js';
import { changeArtworksOrder } from '../interactors/artwork.js';

function create_fragment(ctx) {
	let div4;
	let div2;
	let div0;
	let t1;
	let div1;
	let t2;
	let t3;
	let t4;
	let t5;
	let div3;
	let a0;
	let t7;
	let a1;
	let mounted;
	let dispose;

	return {
		c() {
			div4 = element("div");
			div2 = element("div");
			div0 = element("div");
			div0.innerHTML = `<b>Gallery</b>`;
			t1 = space();
			div1 = element("div");
			t2 = text("| ");
			t3 = text(/*totalCount*/ ctx[1]);
			t4 = text(" artworks");
			t5 = space();
			div3 = element("div");
			a0 = element("a");
			a0.textContent = "Newest";
			t7 = space();
			a1 = element("a");
			a1.textContent = "Oldest";
			attr(div0, "class", "title svelte-hsxupt");
			set_style(div1, "color", "#909090");
			attr(div2, "class", "left svelte-hsxupt");
			attr(a0, "href", "");
			attr(a0, "class", "svelte-hsxupt");
			toggle_class(a0, "selected", /*createdAtOrder*/ ctx[0] === 'desc');
			attr(a1, "href", "");
			attr(a1, "class", "svelte-hsxupt");
			toggle_class(a1, "selected", /*createdAtOrder*/ ctx[0] === 'asc');
			attr(div3, "class", "right svelte-hsxupt");
			attr(div4, "class", "navbar svelte-hsxupt");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div2);
			append(div2, div0);
			append(div2, t1);
			append(div2, div1);
			append(div1, t2);
			append(div1, t3);
			append(div1, t4);
			append(div4, t5);
			append(div4, div3);
			append(div3, a0);
			append(div3, t7);
			append(div3, a1);

			if (!mounted) {
				dispose = [
					listen(div0, "click", /*click_handler*/ ctx[4]),
					listen(a0, "click", /*click_handler_1*/ ctx[5]),
					listen(a1, "click", /*click_handler_2*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*totalCount*/ 2) set_data(t3, /*totalCount*/ ctx[1]);

			if (dirty & /*createdAtOrder*/ 1) {
				toggle_class(a0, "selected", /*createdAtOrder*/ ctx[0] === 'desc');
			}

			if (dirty & /*createdAtOrder*/ 1) {
				toggle_class(a1, "selected", /*createdAtOrder*/ ctx[0] === 'asc');
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let totalCount;
	let createdAtOrder;
	let $artworksMeta;
	let artworksMeta = subs.artworksMeta();
	component_subscribe($$self, artworksMeta, value => $$invalidate(3, $artworksMeta = value));
	const click_handler = () => goToArtworks();
	const click_handler_1 = () => changeArtworksOrder('desc');
	const click_handler_2 = () => changeArtworksOrder('asc');

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$artworksMeta*/ 8) {
			$: $$invalidate(1, totalCount = $artworksMeta.totalCount);
		}

		if ($$self.$$.dirty & /*$artworksMeta*/ 8) {
			$: $$invalidate(0, createdAtOrder = $artworksMeta.orderByParams[1]);
		}
	};

	return [
		createdAtOrder,
		totalCount,
		artworksMeta,
		$artworksMeta,
		click_handler,
		click_handler_1,
		click_handler_2
	];
}

class Navbar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Navbar;