export function infiniteScroll({fetch, element}) {
  const observer = new IntersectionObserver((entries) => {
    const first = entries[0];
    if (first.isIntersecting)
      fetch();
  }, {threshold: 1});
  observer.observe(element);
}
export async function lazyLoadImage({src, element}) {
  return new Promise((resolve) => {
    const imgObserver = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        const img = new Image();
        img.onload = () => resolve();
        img.src = src;
        observer.unobserve(entry.target);
      }
    });
    imgObserver.observe(element);
  });
}
export function calcMaxImgSize(width, height, maxWidth, maxHeight) {
  return width / height > 1 ? [maxWidth, maxWidth / width * height] : [maxHeight / height * width, maxWidth];
}
