import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import App from "./components/app.svelte.js";
import {connectToDevTools} from "../_snowpack/pkg/svelte-restate/devtools.js";
import appDB from "./db.js";
import initRoutes from "./routes.js";
import muts from "./muts.js";
connectToDevTools(appDB, muts);
initRoutes();
const adminToken = localStorage.getItem("adminToken");
if (adminToken) {
  muts.setIsAdmin(true);
}
var app = new App({
  target: document.body
});
export default app;
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
  undefined /* [snowpack] import.meta.hot */ .dispose(() => {
    app.$destroy();
  });
}
