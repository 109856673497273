/* src/components/app.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import './tailwind_styles.svelte.js';
import ArtworkScreen from './artwork_screen.svelte.js';
import ArtworksScreen from './artworks_screen.svelte.js';
import subs from '../subs.js';

function create_fragment(ctx) {
	let title_value;
	let t;
	let switch_instance;
	let switch_instance_anchor;
	let current;
	document.title = title_value = /*title*/ ctx[0];
	var switch_value = /*screens*/ ctx[4][/*$currentScreen*/ ctx[1]];

	function switch_props(ctx) {
		return {};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	return {
		c() {
			t = space();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			insert(target, t, anchor);

			if (switch_instance) {
				mount_component(switch_instance, target, anchor);
			}

			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*title*/ 1) && title_value !== (title_value = /*title*/ ctx[0])) {
				document.title = title_value;
			}

			if (switch_value !== (switch_value = /*screens*/ ctx[4][/*$currentScreen*/ ctx[1]])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let title;
	let $artwork;
	let $currentScreen;
	let currentScreen = subs.currentScreen();
	component_subscribe($$self, currentScreen, value => $$invalidate(1, $currentScreen = value));
	let artwork = subs.currentArtwork();
	component_subscribe($$self, artwork, value => $$invalidate(5, $artwork = value));

	const screens = {
		'artworks': ArtworksScreen,
		'artwork': ArtworkScreen
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$artwork*/ 32) {
			$: $$invalidate(0, title = $artwork
			? $artwork.name || 'Untitled Artwork'
			: 'Gallery');
		}
	};

	return [title, $currentScreen, currentScreen, artwork, screens, $artwork];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;