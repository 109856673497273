import './artwork_screen.svelte.css';
/* src/components/artwork_screen.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Comments from './comments.svelte.js';
import { toggleArtworkVisability, deleteArtwork } from '../interactors/artwork.js';
import { X, ChevronUp, InformationCircle, Play } from '../../_snowpack/pkg/svelte-hero-icons.js';
import Icon from '../../_snowpack/pkg/svelte-hero-icons/Icon.svelte.js';
import { goToArtworks } from '../routes.js';
import { displayTrackedTime, displayName } from '../artwork.js';
import subs from '../subs.js';
import dayjs from '../../_snowpack/pkg/dayjs.js';

function create_if_block(ctx) {
	let div3;
	let div2;
	let t0;
	let t1;
	let div0;
	let icon0;
	let t2;
	let div1;
	let icon1;
	let t3;
	let current;
	let mounted;
	let dispose;
	let if_block0 = !/*isVideoVisible*/ ctx[0] && create_if_block_5(ctx);
	let if_block1 = /*isVideoVisible*/ ctx[0] && create_if_block_4(ctx);
	icon0 = new Icon({ props: { src: X, class: "w-10 h-10" } });

	icon1 = new Icon({
			props: {
				src: InformationCircle,
				solid: true,
				class: "w-10 h-10"
			}
		});

	let if_block2 = /*isInfoVisible*/ ctx[1] && create_if_block_1(ctx);

	return {
		c() {
			div3 = element("div");
			div2 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			div0 = element("div");
			create_component(icon0.$$.fragment);
			t2 = space();
			div1 = element("div");
			create_component(icon1.$$.fragment);
			t3 = space();
			if (if_block2) if_block2.c();
			attr(div0, "class", "close-btn svelte-ol87xw");
			attr(div1, "class", "info-btn svelte-ol87xw");
			attr(div2, "class", "content svelte-ol87xw");
			attr(div3, "class", "wrapper svelte-ol87xw");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div2);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t0);
			if (if_block1) if_block1.m(div2, null);
			append(div2, t1);
			append(div2, div0);
			mount_component(icon0, div0, null);
			append(div2, t2);
			append(div2, div1);
			mount_component(icon1, div1, null);
			append(div3, t3);
			if (if_block2) if_block2.m(div3, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div0, "click", goToArtworks),
					listen(div1, "click", /*click_handler*/ ctx[10])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!/*isVideoVisible*/ ctx[0]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*isVideoVisible*/ 1) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_5(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div2, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*isVideoVisible*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*isVideoVisible*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_4(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*isInfoVisible*/ ctx[1]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*isInfoVisible*/ 2) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div3, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(icon0.$$.fragment, local);
			transition_in(icon1.$$.fragment, local);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(icon0.$$.fragment, local);
			transition_out(icon1.$$.fragment, local);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_component(icon0);
			destroy_component(icon1);
			if (if_block2) if_block2.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (146:6) {#if !isVideoVisible}
function create_if_block_5(ctx) {
	let div;
	let img;
	let img_src_value;
	let t;
	let current;
	let if_block = /*$artwork*/ ctx[2].videoUrl && create_if_block_6(ctx);

	return {
		c() {
			div = element("div");
			img = element("img");
			t = space();
			if (if_block) if_block.c();
			attr(img, "alt", "");
			if (!src_url_equal(img.src, img_src_value = /*$artwork*/ ctx[2].thumbnailUrl)) attr(img, "src", img_src_value);
			attr(img, "class", "svelte-ol87xw");
			attr(div, "class", "img-wrapper svelte-ol87xw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, img);
			append(div, t);
			if (if_block) if_block.m(div, null);
			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*$artwork*/ 4 && !src_url_equal(img.src, img_src_value = /*$artwork*/ ctx[2].thumbnailUrl)) {
				attr(img, "src", img_src_value);
			}

			if (/*$artwork*/ ctx[2].videoUrl) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$artwork*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_6(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

// (149:10) {#if $artwork.videoUrl}
function create_if_block_6(ctx) {
	let div;
	let icon;
	let current;
	let mounted;
	let dispose;

	icon = new Icon({
			props: {
				src: Play,
				solid: true,
				class: "h-40 w-40"
			}
		});

	return {
		c() {
			div = element("div");
			create_component(icon.$$.fragment);
			attr(div, "class", "play-btn svelte-ol87xw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(icon, div, null);
			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*showVideo*/ ctx[6]);
				mounted = true;
			}
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(icon);
			mounted = false;
			dispose();
		}
	};
}

// (157:6) {#if isVideoVisible}
function create_if_block_4(ctx) {
	let video;
	let source;
	let source_src_value;
	let t0;
	let div1;
	let div0;
	let span;
	let t2;
	let icon;
	let current;
	let mounted;
	let dispose;

	icon = new Icon({
			props: {
				src: ChevronUp,
				solid: true,
				class: "h-10 w-10"
			}
		});

	return {
		c() {
			video = element("video");
			source = element("source");
			t0 = space();
			div1 = element("div");
			div0 = element("div");
			span = element("span");
			span.textContent = "Show image";
			t2 = space();
			create_component(icon.$$.fragment);
			if (!src_url_equal(source.src, source_src_value = /*$artwork*/ ctx[2].videoUrl)) attr(source, "src", source_src_value);
			attr(source, "type", "video/mp4");
			video.controls = true;
			video.autoplay = true;
			attr(video, "controlslist", "nodownload");
			attr(video, "class", "svelte-ol87xw");
			attr(div0, "class", "svelte-ol87xw");
			attr(div1, "class", "show-image-btn absolute bg-gradient-to-b from-black h-10 font-bold svelte-ol87xw");
		},
		m(target, anchor) {
			insert(target, video, anchor);
			append(video, source);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, span);
			append(div0, t2);
			mount_component(icon, div0, null);
			current = true;

			if (!mounted) {
				dispose = listen(div1, "click", /*hideVideo*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*$artwork*/ 4 && !src_url_equal(source.src, source_src_value = /*$artwork*/ ctx[2].videoUrl)) {
				attr(source, "src", source_src_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(video);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
			destroy_component(icon);
			mounted = false;
			dispose();
		}
	};
}

// (180:4) {#if isInfoVisible}
function create_if_block_1(ctx) {
	let div3;
	let div2;
	let div1;
	let div0;
	let t0_value = displayName(/*$artwork*/ ctx[2]) + "";
	let t0;
	let t1;
	let dl;
	let dt0;
	let dd0;
	let t3_value = /*$artwork*/ ctx[2].width + "";
	let t3;
	let t4;
	let t5_value = /*$artwork*/ ctx[2].height + "";
	let t5;
	let dt1;
	let dd1;
	let t7_value = displayTrackedTime(/*$artwork*/ ctx[2]) + "";
	let t7;
	let dt2;
	let dd2;
	let t9_value = dayjs.unix(/*$artwork*/ ctx[2].createdAt).format('DD.MM.YYYY HH:mm') + "";
	let t9;
	let t10;
	let t11;
	let comments;
	let current;
	let mounted;
	let dispose;
	let if_block = /*$isAdmin*/ ctx[3] && create_if_block_2(ctx);
	comments = new Comments({});

	return {
		c() {
			div3 = element("div");
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			dl = element("dl");
			dt0 = element("dt");
			dt0.textContent = "Size";
			dd0 = element("dd");
			t3 = text(t3_value);
			t4 = text("x");
			t5 = text(t5_value);
			dt1 = element("dt");
			dt1.textContent = "Tracked time";
			dd1 = element("dd");
			t7 = text(t7_value);
			dt2 = element("dt");
			dt2.textContent = "Created at";
			dd2 = element("dd");
			t9 = text(t9_value);
			t10 = space();
			if (if_block) if_block.c();
			t11 = space();
			create_component(comments.$$.fragment);
			attr(div0, "class", "text-xl");
			attr(div1, "class", "title svelte-ol87xw");
			attr(dt0, "class", "text-gray-400 font-bold uppercase text-sm");
			attr(dd0, "class", "mb-2");
			attr(dt1, "class", "text-gray-400 font-bold uppercase text-sm");
			attr(dd1, "class", "mb-2");
			attr(dt2, "class", "text-gray-400 font-bold uppercase text-sm");
			attr(dd2, "class", "mb-2");
			attr(dl, "class", "mb-2");
			attr(div2, "class", "w-1/2 dark:bg-gray-700 info-content svelte-ol87xw");
			attr(div3, "class", "info svelte-ol87xw");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div2);
			append(div2, div1);
			append(div1, div0);
			append(div0, t0);
			append(div2, t1);
			append(div2, dl);
			append(dl, dt0);
			append(dl, dd0);
			append(dd0, t3);
			append(dd0, t4);
			append(dd0, t5);
			append(dl, dt1);
			append(dl, dd1);
			append(dd1, t7);
			append(dl, dt2);
			append(dl, dd2);
			append(dd2, t9);
			append(div2, t10);
			if (if_block) if_block.m(div2, null);
			append(div2, t11);
			mount_component(comments, div2, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div2, "click", onInfoContentClick),
					listen(div3, "click", /*click_handler_2*/ ctx[12])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*$artwork*/ 4) && t0_value !== (t0_value = displayName(/*$artwork*/ ctx[2]) + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*$artwork*/ 4) && t3_value !== (t3_value = /*$artwork*/ ctx[2].width + "")) set_data(t3, t3_value);
			if ((!current || dirty & /*$artwork*/ 4) && t5_value !== (t5_value = /*$artwork*/ ctx[2].height + "")) set_data(t5, t5_value);
			if ((!current || dirty & /*$artwork*/ 4) && t7_value !== (t7_value = displayTrackedTime(/*$artwork*/ ctx[2]) + "")) set_data(t7, t7_value);
			if ((!current || dirty & /*$artwork*/ 4) && t9_value !== (t9_value = dayjs.unix(/*$artwork*/ ctx[2].createdAt).format('DD.MM.YYYY HH:mm') + "")) set_data(t9, t9_value);

			if (/*$isAdmin*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(div2, t11);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(comments.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(comments.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block) if_block.d();
			destroy_component(comments);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (196:10) {#if $isAdmin }
function create_if_block_2(ctx) {
	let t0;
	let button;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*$artwork*/ ctx[2].isPublic) return create_if_block_3;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			t0 = space();
			button = element("button");
			button.textContent = "Delete";
			attr(button, "class", "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded");
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, t0, anchor);
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_1*/ ctx[11]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(t0.parentNode, t0);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(t0);
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (203:12) {:else}
function create_else_block(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Publish";
			attr(button, "class", "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*togglePublic*/ ctx[8]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (197:12) {#if $artwork.isPublic}
function create_if_block_3(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Unpublish";
			attr(button, "class", "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*togglePublic*/ ctx[8]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let mounted;
	let dispose;
	let if_block = /*$artwork*/ ctx[2] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(window, "keydown", /*onKeydown*/ ctx[9]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*$artwork*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$artwork*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			dispose();
		}
	};
}

function onInfoContentClick(e) {
	e.preventDefault();
	e.stopPropagation();
	e.stopImmediatePropagation();
	return false;
}

function instance($$self, $$props, $$invalidate) {
	let $artwork;
	let $isAdmin;
	const artwork = subs.currentArtwork();
	component_subscribe($$self, artwork, value => $$invalidate(2, $artwork = value));
	const isAdmin = subs.isAdmin();
	component_subscribe($$self, isAdmin, value => $$invalidate(3, $isAdmin = value));
	let isVideoVisible = false;
	let isInfoVisible = false;

	function showVideo() {
		if ($artwork === null || $artwork === void 0
		? void 0
		: $artwork.videoUrl) {
			$$invalidate(0, isVideoVisible = true);
		}
	}

	function hideVideo() {
		$$invalidate(0, isVideoVisible = false);
	}

	function togglePublic() {
		if ($artwork === null) return;
		toggleArtworkVisability($artwork.uid, !$artwork.isPublic);
	}

	function onKeydown(e) {
		if (e.key === 'Escape') goToArtworks();
	}

	const click_handler = () => $$invalidate(1, isInfoVisible = true);
	const click_handler_1 = () => deleteArtwork($artwork.uid);
	const click_handler_2 = () => $$invalidate(1, isInfoVisible = false);

	return [
		isVideoVisible,
		isInfoVisible,
		$artwork,
		$isAdmin,
		artwork,
		isAdmin,
		showVideo,
		hideVideo,
		togglePublic,
		onKeydown,
		click_handler,
		click_handler_1,
		click_handler_2
	];
}

class Artwork_screen extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Artwork_screen;