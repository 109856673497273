import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import axios from "../_snowpack/pkg/axios.js";
function sendActions(actions) {
  const adminToken = localStorage.getItem("adminToken");
  const headers = adminToken ? {"X-GALLERY-ADMIN": adminToken} : {};
  const apiUrl = __SNOWPACK_ENV__.NODE_ENV === "production" ? "https://api.stephanart.work" : "http://localhost:8787";
  return axios.post(apiUrl, {actions}, {headers});
}
export async function getArtworks({offset, orderByParams, withCount}) {
  const actions = [{name: "get-artworks", params: {offset, orderByParams}}];
  if (withCount) {
    actions.push({name: "get-artworks-count"});
  }
  const resp = await sendActions(actions);
  const artworks = resp.data["get-artworks"].map((a) => ({
    uid: a.uid,
    isPublic: a.isPublic,
    createdAt: a.createdAt,
    trackedTime: a.trackedTime,
    width: a.width,
    height: a.height,
    name: a.name,
    thumbnailUrl: a.imageUrl,
    thumbnailSmallUrl: a.thumbnailUrl,
    videoUrl: a.videoUrl
  }));
  const totalCount = resp.data["get-artworks-count"];
  return {artworks, totalCount};
}
export async function getArtwork(uid) {
  const resp = await sendActions([{name: "get-artwork", params: {uid}}]);
  const apiArtwork = resp.data["get-artwork"];
  if (!apiArtwork) {
    return null;
  }
  return {
    uid: apiArtwork.uid,
    isPublic: apiArtwork.isPublic,
    createdAt: apiArtwork.createdAt,
    trackedTime: apiArtwork.trackedTime,
    width: apiArtwork.width,
    height: apiArtwork.height,
    name: apiArtwork.name,
    thumbnailUrl: apiArtwork.imageUrl,
    thumbnailSmallUrl: apiArtwork.thumbnailUrl,
    videoUrl: apiArtwork.videoUrl
  };
}
export async function toggleArtworkVisability(uid, isPublic) {
  const resp = await sendActions([{name: "toggle-artwork-visability", params: {uid, isPublic}}]);
  return resp.data["toggle-artwork-visability"];
}
export async function deleteArtwork(uid) {
  const resp = await sendActions([{name: "delete-artwork", params: {uid}}]);
  return resp.data["delete-artwork"];
}
