import { S as SvelteComponent, i as init, s as safe_not_equal, e as empty, a as insert, d as detach, n as noop, b as svg_element, c as set_svg_attributes, g as get_spread_update, f as attr, h as destroy_each, j as assign } from '../common/index-fc974840.js';

/* node_modules/svelte-hero-icons/Icon.svelte generated by Svelte v3.44.1 */

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

// (24:0) {#if src && src != []}
function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*solid*/ ctx[2]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (40:2) {:else}
function create_else_block(ctx) {
	let svg;
	let svg_class_value;
	let each_value_1 = /*src*/ ctx[1][1] ?? [];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			svg = svg_element("svg");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "fill", "none");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", "currentColor");
			attr(svg, "class", svg_class_value = "heroicon outline " + /*customClass*/ ctx[3]);
			attr(svg, "aria-hidden", /*ariaHidden*/ ctx[4]);
			attr(svg, "width", /*size*/ ctx[0]);
			attr(svg, "height", /*size*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(svg, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*src*/ 2) {
				each_value_1 = /*src*/ ctx[1][1] ?? [];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(svg, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (dirty & /*customClass*/ 8 && svg_class_value !== (svg_class_value = "heroicon outline " + /*customClass*/ ctx[3])) {
				attr(svg, "class", svg_class_value);
			}

			if (dirty & /*ariaHidden*/ 16) {
				attr(svg, "aria-hidden", /*ariaHidden*/ ctx[4]);
			}

			if (dirty & /*size*/ 1) {
				attr(svg, "width", /*size*/ ctx[0]);
			}

			if (dirty & /*size*/ 1) {
				attr(svg, "height", /*size*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (25:2) {#if solid}
function create_if_block_1(ctx) {
	let svg;
	let svg_class_value;
	let each_value = /*src*/ ctx[1][0] ?? [];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "0 0 20 20");
			attr(svg, "fill", "currentColor");
			attr(svg, "class", svg_class_value = "heroicon solid " + /*customClass*/ ctx[3]);
			attr(svg, "aria-hidden", /*ariaHidden*/ ctx[4]);
			attr(svg, "width", /*size*/ ctx[0]);
			attr(svg, "height", /*size*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(svg, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*src*/ 2) {
				each_value = /*src*/ ctx[1][0] ?? [];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(svg, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*customClass*/ 8 && svg_class_value !== (svg_class_value = "heroicon solid " + /*customClass*/ ctx[3])) {
				attr(svg, "class", svg_class_value);
			}

			if (dirty & /*ariaHidden*/ 16) {
				attr(svg, "aria-hidden", /*ariaHidden*/ ctx[4]);
			}

			if (dirty & /*size*/ 1) {
				attr(svg, "width", /*size*/ ctx[0]);
			}

			if (dirty & /*size*/ 1) {
				attr(svg, "height", /*size*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (51:6) {#each src[1] ?? [] as att}
function create_each_block_1(ctx) {
	let path;
	let path_levels = [/*att*/ ctx[5]];
	let path_data = {};

	for (let i = 0; i < path_levels.length; i += 1) {
		path_data = assign(path_data, path_levels[i]);
	}

	return {
		c() {
			path = svg_element("path");
			set_svg_attributes(path, path_data);
		},
		m(target, anchor) {
			insert(target, path, anchor);
		},
		p(ctx, dirty) {
			set_svg_attributes(path, path_data = get_spread_update(path_levels, [dirty & /*src*/ 2 && /*att*/ ctx[5]]));
		},
		d(detaching) {
			if (detaching) detach(path);
		}
	};
}

// (35:6) {#each src[0] ?? [] as att}
function create_each_block(ctx) {
	let path;
	let path_levels = [/*att*/ ctx[5]];
	let path_data = {};

	for (let i = 0; i < path_levels.length; i += 1) {
		path_data = assign(path_data, path_levels[i]);
	}

	return {
		c() {
			path = svg_element("path");
			set_svg_attributes(path, path_data);
		},
		m(target, anchor) {
			insert(target, path, anchor);
		},
		p(ctx, dirty) {
			set_svg_attributes(path, path_data = get_spread_update(path_levels, [dirty & /*src*/ 2 && /*att*/ ctx[5]]));
		},
		d(detaching) {
			if (detaching) detach(path);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*src*/ ctx[1] && /*src*/ ctx[1] != [] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*src*/ ctx[1] && /*src*/ ctx[1] != []) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { src = [] } = $$props;
	let { size = "100%" } = $$props;
	let { solid = false } = $$props;
	let { class: customClass = "" } = $$props;
	let { ariaHidden = false } = $$props;

	if (size !== "100%") {
		if (size.slice(-1) != "x" && size.slice(-1) != "m" && size.slice(-1) != "%") {
			try {
				size = parseInt(size) + "px";
			} catch(error) {
				size = "100%";
			}
		}
	}

	$$self.$$set = $$props => {
		if ('src' in $$props) $$invalidate(1, src = $$props.src);
		if ('size' in $$props) $$invalidate(0, size = $$props.size);
		if ('solid' in $$props) $$invalidate(2, solid = $$props.solid);
		if ('class' in $$props) $$invalidate(3, customClass = $$props.class);
		if ('ariaHidden' in $$props) $$invalidate(4, ariaHidden = $$props.ariaHidden);
	};

	return [size, src, solid, customClass, ariaHidden];
}

class Icon extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			src: 1,
			size: 0,
			solid: 2,
			class: 3,
			ariaHidden: 4
		});
	}
}

export default Icon;
