import './artwork_card.svelte.css';
/* src/components/artwork_card.svelte generated by Svelte v3.44.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	src_url_equal,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../../_snowpack/pkg/svelte.js';
import { displayName, displayTrackedTime } from '../artwork.js';
import subs from '../subs.js';
import { goToArtwork } from '../routes.js';
import { lazyLoadImage } from '../utils.js';
import dayjs from '../../_snowpack/pkg/dayjs.js';
import Icon from '../../_snowpack/pkg/svelte-hero-icons/Icon.svelte.js';
import { Eye, EyeOff } from '../../_snowpack/pkg/svelte-hero-icons.js';

function create_if_block(ctx) {
	let div3;
	let i;
	let t0;
	let img;
	let img_alt_value;
	let img_src_value;
	let t1;
	let div2;
	let div0;
	let t2_value = displayName(/*$artwork*/ ctx[1]) + "";
	let t2;
	let t3;
	let div1;
	let t4_value = dayjs.unix(/*$artwork*/ ctx[1].createdAt).format('DD.MM.YYYY HH:mm') + "";
	let t4;
	let t5;
	let t6;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*$artwork*/ ctx[1].trackedTime && create_if_block_3(ctx);
	let if_block1 = /*$isAdmin*/ ctx[6] && create_if_block_1(ctx);

	return {
		c() {
			div3 = element("div");
			i = element("i");
			t0 = space();
			img = element("img");
			t1 = space();
			div2 = element("div");
			div0 = element("div");
			t2 = text(t2_value);
			t3 = space();
			div1 = element("div");
			t4 = text(t4_value);
			t5 = space();
			if (if_block0) if_block0.c();
			t6 = space();
			if (if_block1) if_block1.c();
			set_style(i, "padding-bottom", /*$artwork*/ ctx[1].height / /*$artwork*/ ctx[1].width * 100 + "%");
			attr(i, "class", "svelte-1j1fg3a");
			attr(img, "alt", img_alt_value = displayName(/*$artwork*/ ctx[1]));

			if (!src_url_equal(img.src, img_src_value = /*isImgLoaded*/ ctx[3]
			? /*imgSrc*/ ctx[5]
			: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=')) attr(img, "src", img_src_value);

			attr(img, "class", "svelte-1j1fg3a");
			attr(div0, "class", "font-bold");
			attr(div2, "class", "info svelte-1j1fg3a");
			toggle_class(div2, "hidden", !/*showInfo*/ ctx[4]);
			attr(div3, "class", "wrapper cursor-pointer svelte-1j1fg3a");
			set_style(div3, "width", /*$artwork*/ ctx[1].width * 200 / /*$artwork*/ ctx[1].height + "px");
			set_style(div3, "flex-grow", /*$artwork*/ ctx[1].width * 200 / /*$artwork*/ ctx[1].height);
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, i);
			append(div3, t0);
			append(div3, img);
			/*img_binding*/ ctx[9](img);
			append(div3, t1);
			append(div3, div2);
			append(div2, div0);
			append(div0, t2);
			append(div2, t3);
			append(div2, div1);
			append(div1, t4);
			append(div2, t5);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t6);
			if (if_block1) if_block1.m(div2, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div3, "click", /*click_handler*/ ctx[10]),
					listen(div3, "mouseenter", /*mouseenter_handler*/ ctx[11]),
					listen(div3, "mouseleave", /*mouseleave_handler*/ ctx[12])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*$artwork*/ 2) {
				set_style(i, "padding-bottom", /*$artwork*/ ctx[1].height / /*$artwork*/ ctx[1].width * 100 + "%");
			}

			if (!current || dirty & /*$artwork*/ 2 && img_alt_value !== (img_alt_value = displayName(/*$artwork*/ ctx[1]))) {
				attr(img, "alt", img_alt_value);
			}

			if (!current || dirty & /*isImgLoaded, imgSrc*/ 40 && !src_url_equal(img.src, img_src_value = /*isImgLoaded*/ ctx[3]
			? /*imgSrc*/ ctx[5]
			: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=')) {
				attr(img, "src", img_src_value);
			}

			if ((!current || dirty & /*$artwork*/ 2) && t2_value !== (t2_value = displayName(/*$artwork*/ ctx[1]) + "")) set_data(t2, t2_value);
			if ((!current || dirty & /*$artwork*/ 2) && t4_value !== (t4_value = dayjs.unix(/*$artwork*/ ctx[1].createdAt).format('DD.MM.YYYY HH:mm') + "")) set_data(t4, t4_value);

			if (/*$artwork*/ ctx[1].trackedTime) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(div2, t6);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*$isAdmin*/ ctx[6]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*$isAdmin*/ 64) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (dirty & /*showInfo*/ 16) {
				toggle_class(div2, "hidden", !/*showInfo*/ ctx[4]);
			}

			if (!current || dirty & /*$artwork*/ 2) {
				set_style(div3, "width", /*$artwork*/ ctx[1].width * 200 / /*$artwork*/ ctx[1].height + "px");
			}

			if (!current || dirty & /*$artwork*/ 2) {
				set_style(div3, "flex-grow", /*$artwork*/ ctx[1].width * 200 / /*$artwork*/ ctx[1].height);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			/*img_binding*/ ctx[9](null);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (69:6) {#if $artwork.trackedTime }
function create_if_block_3(ctx) {
	let div;
	let t_value = displayTrackedTime(/*$artwork*/ ctx[1]) + "";
	let t;

	return {
		c() {
			div = element("div");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*$artwork*/ 2 && t_value !== (t_value = displayTrackedTime(/*$artwork*/ ctx[1]) + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (72:6) {#if $isAdmin }
function create_if_block_1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_2, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$artwork*/ ctx[1].isPublic) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (75:8) {:else}
function create_else_block(ctx) {
	let icon;
	let current;

	icon = new Icon({
			props: {
				src: EyeOff,
				class: "h-5 w-5",
				solid: true
			}
		});

	return {
		c() {
			create_component(icon.$$.fragment);
		},
		m(target, anchor) {
			mount_component(icon, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(icon, detaching);
		}
	};
}

// (73:8) {#if $artwork.isPublic}
function create_if_block_2(ctx) {
	let icon;
	let current;

	icon = new Icon({
			props: { src: Eye, class: "h-5 w-5", solid: true }
		});

	return {
		c() {
			create_component(icon.$$.fragment);
		},
		m(target, anchor) {
			mount_component(icon, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(icon, detaching);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*$artwork*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$artwork*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$artwork*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let imgSrc;
	let $artwork;
	let $isAdmin;
	const isAdmin = subs.isAdmin();
	component_subscribe($$self, isAdmin, value => $$invalidate(6, $isAdmin = value));
	let { id } = $$props;
	let imgRef;
	let isImgLoaded = false;
	let showInfo = false;
	let artwork = subs.artwork(id);
	component_subscribe($$self, artwork, value => $$invalidate(1, $artwork = value));

	onMount(async () => {
		await lazyLoadImage({ src: imgSrc, element: imgRef });
		$$invalidate(3, isImgLoaded = true);
	});

	function img_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			imgRef = $$value;
			$$invalidate(2, imgRef);
		});
	}

	const click_handler = () => goToArtwork(id);
	const mouseenter_handler = () => $$invalidate(4, showInfo = true);
	const mouseleave_handler = () => $$invalidate(4, showInfo = false);

	$$self.$$set = $$props => {
		if ('id' in $$props) $$invalidate(0, id = $$props.id);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$artwork*/ 2) {
			$: $$invalidate(5, imgSrc = $artwork === null || $artwork === void 0
			? void 0
			: $artwork.thumbnailSmallUrl);
		}
	};

	return [
		id,
		$artwork,
		imgRef,
		isImgLoaded,
		showInfo,
		imgSrc,
		$isAdmin,
		isAdmin,
		artwork,
		img_binding,
		click_handler,
		mouseenter_handler,
		mouseleave_handler
	];
}

class Artwork_card extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { id: 0 });
	}
}

export default Artwork_card;