import appDB from "./db.js";
const meta = appDB.regRootSub("meta", ($root) => $root.meta);
const artworkUids = appDB.regRootSub("artworkUids", ($root) => $root.artworkUids);
const currentArtworkId = appDB.regRootSub("currentArtworkId", ($root) => $root.currentArtworkId);
const artworkEntities = appDB.regRootSub("artworkEntities", ($root) => $root.entities.artworks);
const artwork = appDB.regSub("artwork", () => artworkEntities(), ($entities, [id]) => $entities[id]);
const currentArtwork = appDB.regSub("currentArtwork", () => [artworkEntities(), currentArtworkId()], ([$entities, $id]) => $id ? $entities[$id] : null);
const artworksMeta = appDB.regSub("artworksMeta", () => meta(), ($meta) => $meta.artworks);
const currentScreen = appDB.regRootSub("currentScreen", ($root) => $root.screen);
const isAdmin = appDB.regRootSub("isAdmin", ($root) => $root.isAdmin);
export default {
  artworkUids,
  currentArtworkId,
  artwork,
  currentArtwork,
  artworksMeta,
  currentScreen,
  isAdmin
};
