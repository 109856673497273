import appDB from "./db.js";
const appendArtworks = appDB.regMut("appendArtworks", (draft, [ids, artworks]) => {
  draft.artworkUids = [...new Set(draft.artworkUids.concat(ids))];
  draft.entities.artworks = Object.assign(draft.entities.artworks, artworks);
});
const setArtworks = appDB.regMut("setArtworks", (draft, [ids, artworks]) => {
  draft.artworkUids = ids;
  draft.entities.artworks = artworks;
});
const insertArtwork = appDB.regMut("insertArtwork", (draft, artwork) => {
  draft.entities.artworks[artwork.uid] = artwork;
});
const updateArtwork = appDB.regMut("updateArtwork", (draft, [id, data]) => {
  const artwork = draft.entities.artworks[id];
  draft.entities.artworks[id] = Object.assign(artwork, data);
});
const deleteArtwork = appDB.regMut("deleteArtwork", (draft, id) => {
  draft.artworkUids = draft.artworkUids.filter((i) => i !== id);
  delete draft.entities.artworks[id];
});
const setArtworksLoading = appDB.regMut("setArtworksLoading", (draft, value) => {
  draft.meta.artworks.isLoading = value;
});
const setArtworksMeta = appDB.regMut("setArtworksMeta", (draft, meta) => {
  const currentMeta = draft.meta.artworks || {};
  const newMeta = {...currentMeta, ...meta};
  draft.meta.artworks = newMeta;
});
const setCurrentArtworkId = appDB.regMut("setCurrentArtworkId", (draft, value) => {
  draft.currentArtworkId = value;
});
const setCurrentScreen = appDB.regMut("setCurrentScreen", (draft, value) => {
  draft.screen = value;
});
const setIsAdmin = appDB.regMut("setIsAdmin", (draft, value) => {
  draft.isAdmin = value;
});
export default {
  appendArtworks,
  setArtworks,
  insertArtwork,
  updateArtwork,
  deleteArtwork,
  setArtworksMeta,
  setArtworksLoading,
  setCurrentArtworkId,
  setCurrentScreen,
  setIsAdmin
};
