import muts from "../muts.js";
import subs from "../subs.js";
import appDB from "../db.js";
import {loadArtworks, loadArtwork} from "./artwork.js";
export function goToArtwork(id) {
  const artwork = subs.artwork(id).getState();
  if (artwork) {
    appDB.transaction((tx) => [
      muts.setCurrentArtworkId(id, tx)
    ]);
    return;
  }
  appDB.transaction((tx) => [
    muts.setCurrentScreen("artwork", tx),
    muts.setCurrentArtworkId(id, tx)
  ]);
  loadArtwork(id);
}
export function goToArtworks() {
  appDB.transaction((tx) => [
    muts.setCurrentArtworkId(null, tx),
    muts.setCurrentScreen("artworks", tx)
  ]);
  loadArtworks();
}
